import { 
    ChangeDetectorRef, 
    Component, 
    ElementRef, 
    Inject, 
    NgZone, 
    OnInit,
    ViewChild
} from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from "@angular/material/dialog";
import {
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  Validators,
} from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatDividerModule } from "@angular/material/divider";
import { MatMenuModule } from "@angular/material/menu";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { CommonModule, DatePipe } from "@angular/common";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { 
  Subscription,
  take, 
} from "rxjs";
import { MatSnackBar, MatSnackBarModule } from "@angular/material/snack-bar";
import { FormService } from "src/app/_services/form.service";
import { MatTooltipModule } from "@angular/material/tooltip";
import { mapFailureError, mapRenewalStatus } from "@vex/enums/enumerations";
import { ContainerService } from "src/app/_services/container.service";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { Address } from "@vex/interfaces/dashboard.interface";
import { MatInputModule } from "@angular/material/input";
import { AddressFormComponent } from "../address-form/address-form.component";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { environment } from "src/environments/environment";
import { LicenseModal } from "../license-card.modal";
import { NgxMatIntlTelInputComponent } from "ngx-mat-intl-tel-input";
import { TokenStorageService } from "src/app/_services/token-storage.service";
import { SNACKBAR_DURATION_LARGE, SNACKBAR_DURATION_NORMAL } from "src/static-data/constants";

@Component({
  selector: "view-engineer-modal",
  templateUrl: "./view-engineer.modal.html",
  styles: [],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatDialogModule,
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatDividerModule,
    MatFormFieldModule,
    FormsModule,
    MatSnackBarModule,
    TranslateModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatInputModule,
    MatProgressSpinnerModule,
    DatePipe,
    NgxMatIntlTelInputComponent,   
  ]
})

export class ViewEngineerModal implements OnInit {
  @ViewChild('fileInput') fileInput!: ElementRef;
  
  localStorage = localStorage;



  private subscriptions: Subscription = new Subscription();

  
  public currentDate: Date = new Date();
  public engFormData: any;
  public FormSetup: any = [];
  public engFirstForm!: FormGroup;

  public profileImageUrl: string = '';
  public profileImage: File | null = null;



  public isLoaded: boolean = false;
  public isFormSubmitted: boolean = false;
  public isPassportDisabled: boolean = false;
  public isEdit: boolean = false;
  public isPersonalDisabled: boolean = false;



  constructor(
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    public dialogRef: MatDialogRef<ViewEngineerModal>,
    public dialog: MatDialog,
    public snackbar: MatSnackBar,
    public cdRef: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
    public formSrv: FormService,
    public translateSrv: TranslateService,
    public containerSrv: ContainerService,
    public tokenStorageSrv: TokenStorageService,
    private ngZone: NgZone
  ) {
    const addSub = this.containerSrv.FormSetup_O.subscribe((resp: any) => {
      if(resp) {
        this.FormSetup = resp;
      }
    });

    this.subscriptions.add(addSub);
  }

  ngOnInit() {    
    this.isEdit = this.modalData.isEdit;
    let queryParam: string;
    if (this.modalData.isPrimaryContact) {
      queryParam = `&Primary=${this.modalData.isPrimaryContact}`;
    } else {
      queryParam = `&Amendment=true`;
    }
    
    const FormSub = this.formSrv.getEngineerData(this.modalData.contactId, queryParam).subscribe({
      next: async (value: any) => {
        if (value && value.succeeded && value.data != null) {
          this.engFormData = value.data;  
          // this.engFormData.licenseExpiryDate = this.convertToDate(this.engFormData.licenseExpiryDate);
          this.engFormData.badge = mapRenewalStatus(this.engFormData.renewalStatus || 0);
          this.profileImageUrl = (this.engFormData.contactPhoto != null && this.engFormData.contactPhoto != '' ) 
          ? environment.apiUrl + `/File/Processor?cipherText=${this.engFormData.contactPhoto}`
          : '/assets/img/avatars/avatar.png';
            
          this.engFormData.nationality = this.FormSetup.country.find((elem: any) => elem.id == this.engFormData.natID)?.name || '';
          this.engFormData.entityDiscipline = this.FormSetup.entityDiscipline.find((elem: any) => elem.id == this.engFormData.entityDisciplineID)?.name || '';
          this.isLoaded = await this.generateFormSetup();                
        } else {
          this.snackbar.open(mapFailureError(value.errorCode, this.translateSrv), undefined, {duration: SNACKBAR_DURATION_LARGE});
          this.dialogRef.close();
          return;
        }
      },
      error: (err: any) => {
        this.snackbar.open(err, undefined, { duration: SNACKBAR_DURATION_LARGE });
        this.dialogRef.close();
      },
    });

    this.subscriptions.add(FormSub);

  }

  private async generateFormSetup(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      if (!this.engFormData.address || this.engFormData.address.length == 0) {
        this.engFormData.address.push({ title: "Main" } as Address);
        this.engFormData.address.push({ title: "Passport Address" } as Address);
      }

     
      let isDisabled: boolean = !this.isEdit;
      this.isPassportDisabled = this.engFormData.passportNo && this.engFormData.passportNo != "";
      this.isPersonalDisabled = this.engFormData.cont_CPR && this.engFormData.cont_CPR != "";

      this.engFirstForm = this.fb.group({
        contactEmail: [
          {
            value: this.engFormData.contactEmail,
            disabled: isDisabled,
          },
          [
            Validators.required, 
            Validators.email
          ]
        ],
        contactMobile: [
          { 
            value: this.engFormData.contactMobile ? this.engFormData.contactMobile.startsWith('+') ? this.engFormData.contactMobile.toString() : ( '+973' + this.engFormData.contactMobile.toString()) : null,
            disabled: isDisabled
          }          
        ],
        contactTel: [
          {
            value: this.engFormData.contactTel ? this.engFormData.contactTel.startsWith('+') ? this.engFormData.contactTel.toString() : ( '+973' + this.engFormData.contactTel.toString()) : null,
            disabled: isDisabled
          }
        ],
        contactFax: [
          {
            value: this.engFormData.contactFax ? this.engFormData.contactFax.startsWith('+') ? this.engFormData.contactFax.toString() : ( '+973' + this.engFormData.contactFax.toString()) : null,
            disabled: isDisabled
          },  
        ],        
        cont_CPR: [
          {
            value: this.engFormData.cont_CPR, 
            disabled: this.isPersonalDisabled
          }, 
          (this.engFormData.cont_CPR && this.engFormData.cont_CPR != "" ) ? Validators.required : Validators.nullValidator 
        ],
        cprExpDate: [
          {
            value: this.engFormData.cprExpDate, 
            disabled: isDisabled
          }, 
          (this.engFormData.cont_CPR && this.engFormData.cont_CPR != "" ) ? Validators.required : Validators.nullValidator
        ],
        passportNo: [
          {
            value: this.engFormData.passportNo, 
            disabled: this.isPassportDisabled
          },           
          (this.engFormData.passportNo && this.engFormData.passportNo != "" ) ? Validators.required : Validators.nullValidator 
        ],
        passportExpDt: [          
          {
            value: this.engFormData.passportExpDt, 
            disabled: isDisabled
          }, 
          (this.engFormData.passportNo && this.engFormData.passportNo != "" ) ? Validators.required : Validators.nullValidator 
        ],
      });

        resolve(true);
    });
  }

  public openAddressForm(data: Address | null, isEdit: boolean, index: number = -2): void {
    this.dialog.open(
      AddressFormComponent, {
        data: {
          address: data != null ? data : ({} as Address),
        },
        width: "600px",
      }
    ).afterClosed().pipe(take(1)).subscribe((address: Address) => {      
        if (address) {
          if (isEdit) {
            this.engFormData.address[index] = address;
          } else {
            this.engFormData.address.push(address);
          }
          this.cdRef.markForCheck();
        }
      });
  }

  public getAddressAsString(address: Address) {
    let returnString = [
      address.address1 && `Flat: ${address.address1}`,
      address.address2 && `, Building No: ${address.address2}`,
      address.address3 && `, Road No: ${address.address3}`,
      address.address4 && `, Block No: ${address.address4}`,
      address.address5 && `, Area: ${address.address5}`
    ].filter(Boolean).join('');
    
    return returnString;
  }

  public async editEngineerData() {
    this.isEdit = !this.isEdit;
    await this.generateFormSetup();

  }
  triggerFileInput(): void {
    this.fileInput.nativeElement.click();
  }
  
  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files[0]) {
      const file = input.files[0];
      this.profileImage = file;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.profileImageUrl = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }


  viewHistory(engineerId: string) {
    let licenseHistory: any[] = this.engFormData.licenseHistory || []; 
    if(licenseHistory.length > 0) {
      licenseHistory.forEach((item: any) => {
        item.disciplineName = this.FormSetup.disciplineCategories.find((elem: any) => elem.id == item.disciplineCategory)?.name || '';
      });

    this.dialog.open(LicenseModal, {
        data: licenseHistory,
        width: "550px",
      }
    ).afterClosed().pipe(take(1)).subscribe((resp: boolean ) => {      
        
      });
    } else {
      this.snackbar.open("No License History found for the selected user", undefined, { duration: SNACKBAR_DURATION_LARGE });
    }
    


  
  }

  saveEngineerForm() {
    this.markFormGroupTouched(this.engFirstForm);  

    if(this.engFirstForm.valid) {
      let engForm: any = this.engFormData;

      let updatedField = this.engFirstForm.value;
      Object.keys(updatedField).forEach((key: string) => {
        engForm[key] = updatedField[key];
      });
  
      engForm.address = this.engFormData.address;
  
      this.isFormSubmitted = true;
      const mData = JSON.stringify(engForm);
      const formData = new FormData();
      formData.append("data", mData);
      if(this.profileImage) {
        formData.append("file", this.profileImage, this.profileImage.name);
      }
  
      const UpgradeFormSub = this.formSrv.updateEngineer(formData).subscribe({
        next: async (value: any) => {
          this.isFormSubmitted = false; 
          if (value && value.succeeded) {
            if(this.profileImage) {
              if(this.modalData.isPrimaryContact) {
                let currentUser = this.tokenStorageSrv.getUser();
                currentUser.avatarImage = value.data;
                this.tokenStorageSrv.saveUser(currentUser);
                this.dialogRef.close(true);
              } else {
                this.dialogRef.close(value.data);
              }
              this.snackbar.open("Your information has been updated successfully.", undefined, { duration: SNACKBAR_DURATION_LARGE });
              this.profileImage = null;
            } else {
              this.snackbar.open("Your information has been updated successfully.", undefined, { duration: SNACKBAR_DURATION_LARGE });
              this.dialogRef.close(true); 
            } 
          } else {          
            this.snackbar.open(mapFailureError(value.errorCode, this.translateSrv), undefined, { duration: SNACKBAR_DURATION_LARGE });
          }
        },
        error: (err: any) => {
          this.isFormSubmitted = false; 
          this.snackbar.open("Error Occured while processing your request", undefined,{ duration: SNACKBAR_DURATION_LARGE });
        },
      });
  
      this.subscriptions.add(UpgradeFormSub);
    } else {
      this.snackbar.open("Please enter correct details", undefined, {duration: SNACKBAR_DURATION_NORMAL });
    }
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach(control => {
      control.markAsTouched();
  
      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }

}


