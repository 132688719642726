import { Component, Input, OnInit } from "@angular/core";
import { VexPopoverRef } from "@vex/components/vex-popover/vex-popover-ref";
import { MatRippleModule } from "@angular/material/core";
import { Router, RouterLink } from "@angular/router";
import { MatIconModule } from "@angular/material/icon";
import { TranslateModule } from "@ngx-translate/core";
import { ViewEngineerModal } from "src/app/_modals/view-engineer/view-engineer.modal";
import { MatDialog } from "@angular/material/dialog";
import { TokenStorageService } from "src/app/_services/token-storage.service";
import { take } from "rxjs";
import { ChangePasswordModal } from "src/app/_modals/change-password.modal";
import { CommonModule } from "@angular/common";
import { VexLayoutService } from "@vex/services/vex-layout.service";
import { ResponseActionModal } from "src/app/_modals/response-form/response-form.modal";
import { ImprovedApplicationStatus } from "@vex/enums/enumerations";
import { ContactService } from "src/app/_services/contact.service";

@Component({
  selector: "vex-sidenav-user-menu",
  templateUrl: "./sidenav-user-menu.component.html",
  styleUrls: ["./sidenav-user-menu.component.scss"],
  imports: [
    MatRippleModule, 
    RouterLink, 
    MatIconModule,
    TranslateModule,
    CommonModule
  ],
  standalone: true,
})
export class SidenavUserMenuComponent implements OnInit {
  public appLevel: any;
  constructor(
    private readonly popoverRef: VexPopoverRef,
    public contacSrv: ContactService,
    private router: Router,
    public dialog: MatDialog,
    public tokenStorageSrv: TokenStorageService,
    public layoutSrv: VexLayoutService,
  ) {
    this.appLevel = popoverRef.data;
  }

  ngOnInit(): void {}

  public logOut(): void {
    this.contacSrv.logout();
    setTimeout(() => this.popoverRef.close(), 250);
  }

  public viewProfile(): void {    
    let contactId: string =  this.tokenStorageSrv.getUser().employeeID || '';

    if(contactId != '') {
      this.popoverRef.close();
      this.dialog.open(
        ViewEngineerModal, {
          data: {
            contactId: contactId,
            isEdit: false,
            isPrimaryContact: true
          },
          width: '800px',
          minWidth: '320px'
        }
      ).afterClosed().pipe(take(1)).subscribe((response: any) => {
        
      });
    }
  }

  public changePassword() {
    this.popoverRef.close();
    this.dialog.open(
      ChangePasswordModal, {
        width: "450px",
      }
    ).afterClosed().pipe(take(1)).subscribe((response: any) => {
      
    });
  }

  viewOpenApplication() {
    
  }




  public openMoreInfoModal() {
    this.popoverRef.close();
    if(this.appLevel.route != '') {
      if(this.appLevel.param != null) {
        this.router.navigate([this.appLevel.route], { queryParams: this.appLevel.param });
      } else {
        this.router.navigate([this.appLevel.route]);
      }
       
    } else {
      if(this.appLevel.levelID == ImprovedApplicationStatus.RequireFurtherInformation || this.appLevel.levelID == ImprovedApplicationStatus.ApplicationFinalDocUpload) {
        let title: string = this.appLevel.levelID == ImprovedApplicationStatus.RequireFurtherInformation ?   'Further Information Required' : 'Final Document Upload'; 
        this.dialog.open(
          ResponseActionModal, {
            data: {
              appID: this.appLevel.appID,
              title: title,
            },
            width: "500px",
          }
        ).afterClosed().pipe(take(1)).subscribe((response: any) => {
          if(response.isSubmit) {
            this.router.navigate(["/form/comp-form"], {
              queryParams: { 
                formType: this.appLevel.oid, 
                appID: response.appID,
                resubmit: true 
              }
            });
          }
        });
      }
    }
  }


}
