import { TranslateService } from "@ngx-translate/core";
import { StatusButton } from "@vex/interfaces/dashboard.interface";

export enum RenewalStatus {
    Active = 0,
    BeforeExpiry = 1, //eligible to renew
    InGracePeriod = 2, //
    InPenaltyPeriod = 3,
    InActive = 4
}

export const ApplicationType = {
    Renewal: [337,358,359],
    Upgrade: [331,332,351,352,353]
}

export enum RoutingTab {
    Home = "",
    Dashboard = "dashboard",
    CompanyProfile = "comp-profile",
    UnsubmittedApplications = "unsubmitted-applications",
    OpenApplications ="open-applications"
}

export enum FormPanel {
    Memberships = 300,
    Services = 311,
    RegisterCompany = 335,
    AddEngineersEngineeringOffice = 336,
    AddEngineersContractors = 342,
    AddEngineersPublicSector = 338,
    AddEngineersRetired = 343,
    LicenseRenewalEngineer = 337, // Differentiate from company renewal
    UpgradeLicenseFromBToA = 331,
    UpgradeLicenseFromCToB = 332,
    BasicDataAmendmentCompany = 333, // Differentiate from engineer amendment
    Cabinet = 341,
    Account = 345,
    OfficeDocument = 346,
    Applications = 349,
    MembershipsCompany = 301,  // Added for clarity
    Engineers = 350,
    LicenseRenewalCompany = 358, // Differentiate from engineer renewal
    LicenseRenewalGovt = 359,
    UpgradeLicenseFromBToACompany = 351, // Added for clarity
    UpgradeLicenseFromCToBCompany = 352, // Added for clarity
    UpgradeLicenseFromDToC = 353,
    EngineerBasicDataAmendment = 354,
    ApplicationsCompany = 360,  // Added for clarity
    EngineersDocument = 361
}

export enum OldApplicationStatus {
    OpenApplicaton = 0,
    PaymentPending = 1,
    InProgress = 2,
    FurtherInformation = 3,
    Approved = 4,
    Rejected = 5,
    FinalDocUpload = 6,
    FinalSubmissionPayment = 8,
    LicenseIssued = 7
}

export enum ImprovedApplicationStatus {
    ApplcationNotSubmitted = 0,
    ApplicationFormPayment = 1,
    ApplicationInProgress = 2,
    RequireFurtherInformation = 3,
    ApplicationApproved = 4,
    ApplicationRejected = 5,
    ApplicationCancelled = 9,
    ApplicationFinalDocUpload = 6,
    ApplicationLicensePayment = 8,
    AwaitingApproval = 7,
}

export enum ApplicationStatus {
    Application = 1,
    Evaluation = 2,
    Approval = 3,
    ApplicationSubmissionFeesAndReceipt = 4,
    FeesCollectionAndLicenseIssuance = 5,
    CommitteeApproval = 6,
    BoardApproval = 7,
    Review = 8,
    StatementIssue = 9,
    FinalDocUpload = 10
}

export enum FailureError {
    AccountNotFound = 4,
    Failed = 10,
    ApplicantOwnerError = 17, 
    PrimaryContactExists = 18,
    EmailAlreadyExist = 11,
    NotBahraini = 19,
    OwnerExist = 22,
    ManagerExist = 21,
    AccountExist = 23,
    PasswordMatchOldPassword = 5
}

export function mapRenewalStatus(status: number): string {
    switch (status) {
        case RenewalStatus.Active:
            return  "<span class='bg-green-100 text-green-800 text-sm font-medium px-2.5 py-0.5 rounded'>Active</span>";
        case RenewalStatus.BeforeExpiry:
            return  "<span class='bg-blue-100 text-blue-800 text-sm font-medium px-2.5 py-0.5 rounded'>Eligible to Renew</span>";
        case RenewalStatus.InGracePeriod:
            return  "<span class='bg-yellow-100 text-yellow-800 text-sm font-medium px-2.5 py-0.5 rounded'>In Grace Period</span>";
        case RenewalStatus.InPenaltyPeriod:
            return "<span class='bg-pink-100 text-pink-800 text-sm font-medium px-2.5 py-0.5 rounded'>In Penality Period</span>";
        case RenewalStatus.InActive:
            return "<span class='bg-red-100 text-red-800 text-sm font-medium px-2.5 py-0.5 rounded'>Inactive</span>";
        default:
            return 'Unknown';
    }
}
// content_paste_search
export function mapWorkFlow(workFlow: number[], isPaid: number): StatusButton[] {
    let returnArray: StatusButton[] = [];
    let isGoneOnceToInProgress: boolean = false;
    workFlow.forEach((item: number) => {
        switch(item) {
            case 4:
                returnArray.push({
                    id: ImprovedApplicationStatus.ApplicationFormPayment,
                    sort: 0,
                    parent: true,
                    icon: 'mat:payments',
                    title: 'Form Payment',
                    tooltip: 'Form Payment',
                    isCurrentStatus: 'text-orange-500 blinker-animation',
                    isAboveCurrentStatus: 'text-green-600',
                    isBelowCurrentStatus: 'text-gray-600',
                    isPaid:  isPaid == 1,
                });
                break;
            case 2:
            case 8:
            case 9:
                if(!isGoneOnceToInProgress) {
                    returnArray.push({
                        parent: true,
                        id: ImprovedApplicationStatus.ApplicationInProgress,
                        sort: 0,
                        icon: 'mat:content_paste_search',
                        title: 'Verification',
                        tooltip: 'Verification Process',
                        isCurrentStatus: 'text-orange-500 blinker-animation',
                        isAboveCurrentStatus: 'text-green-600',
                        isBelowCurrentStatus: 'text-gray-600',
                        isPaid: false,
                      },
                      {
                        parent: false,
                        id: ImprovedApplicationStatus.RequireFurtherInformation,
                        sort: 0,
                        icon: 'mat:ballot',
                        title: 'Additional Information',
                        tooltip: 'Require Additional Information',
                        isCurrentStatus: 'text-orange-500 blinker-animation',
                        isAboveCurrentStatus: 'text-green-600',
                        isBelowCurrentStatus: 'text-gray-600',
                        isPaid: false,

                      });

                    isGoneOnceToInProgress = !isGoneOnceToInProgress;
                }                
                break;
            case 3: 
                returnArray.push({
                    parent: false,
                    id: ImprovedApplicationStatus.ApplicationApproved,
                    sort: 0,
                    icon: 'mat:verified_user',
                    title: 'Approved',
                    tooltip: 'Application Approved',
                    isCurrentStatus: 'text-green-600',
                    isAboveCurrentStatus: 'text-green-600',
                    isBelowCurrentStatus: 'text-gray-600',
                    isPaid: false,
                },
                {
                    parent: false,
                    id: ImprovedApplicationStatus.ApplicationRejected,
                    sort: 0,
                    icon: 'mat:gpp_bad',
                    title: 'Rejected',
                    tooltip: 'Application Rejected',
                    isCurrentStatus: 'text-red-600',
                    isAboveCurrentStatus: 'text-red-600',
                    isBelowCurrentStatus: 'text-gray-600',
                    isPaid: false,
                },
                {
                    parent: false,
                    id: ImprovedApplicationStatus.ApplicationCancelled,
                    sort: 0,
                    icon: 'mat:do_not_disturb_on',
                    title: 'Cancelled',
                    tooltip: 'Application Cancelled',
                    isCurrentStatus: 'text-red-600',
                    isAboveCurrentStatus: 'text-red-600',
                    isBelowCurrentStatus: 'text-gray-600',
                    isPaid: false,
                },
            );
                break;
            case 10:
                returnArray.push({
                    parent: false,
                    id: ImprovedApplicationStatus.ApplicationFinalDocUpload,
                    sort: 0,
                    icon: 'mat:cloud_upload',
                    title: 'Upload Documents',
                    tooltip: 'Upload Final Documents',
                    isCurrentStatus: 'text-orange-500 blinker-animation',
                    isAboveCurrentStatus: 'text-green-600',
                    isBelowCurrentStatus: 'text-gray-600',
                    isPaid: false,

                  });
                break;
            case 5:
                returnArray.push({
                    id: ImprovedApplicationStatus.ApplicationLicensePayment,
                    sort: 0,
                    parent: true,
                    icon: 'mat:payments',
                    title: 'License Payment',
                    tooltip: 'License Payment',
                    isCurrentStatus: 'text-orange-500 blinker-animation',
                    isAboveCurrentStatus: 'text-green-600',
                    isBelowCurrentStatus: 'text-gray-600',
                    isPaid:  isPaid == 2,
                },
                {
                    id: ImprovedApplicationStatus.AwaitingApproval,
                    sort: 0,
                    parent: true,
                    icon: 'mat:approval',
                    title: 'License Issuance',
                    tooltip: 'Awaiting Approval',
                    isCurrentStatus: 'text-orange-500 blinker-animation',
                    isAboveCurrentStatus: 'text-green-600',
                    isBelowCurrentStatus: 'text-gray-600',
                    isPaid: false,
                });
                break;
        }
    });

    return returnArray;
}

// export function mapApplicationStatus(status: number, value: string): string {
//     switch (status) {
//         case ApplicationStatus.Application: 
//             return "<span class='bg-indigo-100 text-indigo-800 text-sm font-medium px-2.5 py-0.5 rounded'>" + value + "</span>";
//         case ApplicationStatus.Evaluation:
//             return "<span class='bg-yellow-100 text-yellow-800 text-sm font-medium px-2.5 py-0.5 rounded'>" + value + "</span>";
//         case ApplicationStatus.Approval:
//         case ApplicationStatus.CommitteeApproval:
//         case ApplicationStatus.BoardApproval:
//             return "<span class='bg-green-100 text-green-800 text-sm font-medium px-2.5 py-0.5 rounded'>" + value + "</span>";
//         case ApplicationStatus.ApplicationSubmissionFeesAndReceipt:
//         case ApplicationStatus.FeesCollectionAndLicenseIssuance:
//             return "<span class='bg-red-100 text-red-800  text-sm font-medium px-2.5 py-0.5 rounded'>" + value + "</span>";
//         case ApplicationStatus.Review:
//             return "<span class='bg-blue-100 text-blue-800  text-sm font-medium px-2.5 py-0.5 rounded'>" + value + "</span>";
//         case ApplicationStatus.FinalDocUpload:
//             return "<span class='bg-purple-100 text-purple-800 text-sm font-medium px-2.5 py-0.5 rounded'>" + value + "</span>";
//         case ApplicationStatus.StatementIssue:
//             return "<span class='bg-blue-100 text-blue-800 text-sm font-medium px-2.5 py-0.5 rounded'>" + value + "</span>";
//         default:
//             return 'Unknown';
//     }
// }

// export function mapRequestType(status: number, value: string): string {
//     switch (status) {        
//         case FormPanel.AddEngineersContractors:
//         case FormPanel.AddEngineersEngineeringOffice:
//         case FormPanel.AddEngineersPublicSector:
//         case FormPanel.AddEngineersRetired:
//             return "<span class='bg-indigo-100 text-indigo-800 text-sm font-medium px-2.5 py-0.5 rounded'>" + value + "</span>";
//         case FormPanel.LicenseRenewalEngineer: 
//         case FormPanel.LicenseRenewalCompany: 
//         case FormPanel.LicenseRenewalGovt:
//             return "<span class='bg-green-100 text-green-800 text-sm font-medium px-2.5 py-0.5 rounded'>" + value +  "</span>";  
//         case FormPanel.UpgradeLicenseFromBToA:
//         case FormPanel.UpgradeLicenseFromCToB: 
//         case FormPanel.UpgradeLicenseFromDToC: 
//         case FormPanel.UpgradeLicenseFromBToACompany: 
//         case FormPanel.UpgradeLicenseFromCToBCompany:
//             return "<span class='bg-yellow-100 text-yellow-800 text-sm font-medium px-2.5 py-0.5 rounded'>" + value + "</span>";  
//         case FormPanel.BasicDataAmendmentCompany:
//         case FormPanel.EngineerBasicDataAmendment:
//             return "<span class='bg-purple-100 text-purple-800 text-sm font-medium px-2.5 py-0.5 rounded'>" + value + "</span>";
//         case FormPanel.RegisterCompany:
//             return "<span class='bg-red-100 text-red-800 text-sm font-medium px-2.5 py-0.5 rounded'>" + value + "</span>";
//         default:
//             return "<span class='bg-pink-100 text-pink-800 text-sm font-medium px-2.5 py-0.5 rounded'>" + value + "</span>";
//     }
// }


export function mapFailureError(error: number, translate: TranslateService): string {
    switch (error) {
        case FailureError.AccountNotFound:
            return translate.instant('FAILURE_ERROR.ACCOUNT_NOT_FOUND');
        case FailureError.Failed:
            return translate.instant('FAILURE_ERROR.FAILED');
        case FailureError.ApplicantOwnerError:
            return translate.instant('FAILURE_ERROR.APPLICANT_OWNER_ERROR');
        case FailureError.PrimaryContactExists:
            return translate.instant('FAILURE_ERROR.PRIMARY_CONTACT_EXISTS');
        case FailureError.EmailAlreadyExist:
            return translate.instant('FAILURE_ERROR.EMAIL_ALREADY_EXIST');
        case FailureError.NotBahraini:
            return translate.instant('FAILURE_ERROR.NOT_BAHRAINI');
        case FailureError.OwnerExist:
            return translate.instant('FAILURE_ERROR.OWNER_EXIST');
        case FailureError.ManagerExist:
            return translate.instant('FAILURE_ERROR.MANAGER_EXIST');
        case FailureError.AccountExist:
            return translate.instant('FAILURE_ERROR.ACCOUNT_EXIST');
        case FailureError.PasswordMatchOldPassword:
            return translate.instant('FAILURE_ERROR.PASSWORD_MATCH_OLD_PASSWORD');
        default:
            return translate.instant('FAILURE_ERROR.UNKNOWN_ERROR');
    }
}


// export function mapOldApplicationStatus(status: number, blinkingAnimation: boolean, value: string): string {
//     switch (status) {
//         case OldApplicationStatus.PaymentPending:
//         case OldApplicationStatus.FinalDocUpload:
//         case OldApplicationStatus.FurtherInformation:
//         case OldApplicationStatus.FinalSubmissionPayment:
//             return `<span class=' text-red-600  text-md font-medium px-2.5 py-0.5 rounded  ${blinkingAnimation ? " blinker-animation ": ""}'> ${value} </span>`;
//         case OldApplicationStatus.InProgress:
//             return `<span class=' text-indigo-800 text-md font-medium px-2.5 py-0.5 rounded  ${blinkingAnimation ? " blinker-animation ": ""}'> ${value} </span>`;
//         case OldApplicationStatus.Approved:
//             return `<span class=' text-green-800 text-md font-medium px-2.5 py-0.5 rounded  ${blinkingAnimation ? " blinker-animation ": ""}'> ${value} </span>`;
//         case OldApplicationStatus.Rejected:
//             return `<span class=' text-red-800 text-md font-medium px-2.5 py-0.5 rounded  ${blinkingAnimation ? " blinker-animation ": ""}'> ${value} </span>`;
//         default:
//             return 'Unknown';
//     }
// }

export function getFormPanel_OID(customerTypeID: number): number | null {
    switch(customerTypeID) {
      case 3:
        return FormPanel.AddEngineersEngineeringOffice;
      case 4:
        return FormPanel.AddEngineersPublicSector;
      case 2:
        return FormPanel.AddEngineersContractors;
      case 12:
        return FormPanel.AddEngineersRetired;
      default:
        return null;
    }

  }