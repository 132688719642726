import { Component, Inject, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatRippleModule } from "@angular/material/core";
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MatRadioModule } from "@angular/material/radio";
import { FormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { MatDividerModule } from "@angular/material/divider";

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatRippleModule,
    MatRadioModule,
    FormsModule,
    TranslateModule,
    MatDialogModule, 
    MatDividerModule
  ],
  selector: "license-modal",
  template: `
    <div 
      class="flex items-center  !px-3 sm:!px-6" 
      mat-dialog-title
    >
      <h2 class="title m-0 flex-auto"> {{'License History' | translate }}</h2>
      <button 
        class="text-secondary" 
        mat-dialog-close 
        mat-icon-button 
        type="button"
      >
        <mat-icon svgIcon="mat:close"></mat-icon>
      </button>
    </div>

    <mat-divider class="text-border"></mat-divider>
    <mat-dialog-content class="flex flex-col !px-3 sm:!px-6">
      <div class="card bg-slate-100 p-3 mb-4" *ngFor="let x of licenseData">
        <div class="flex items-center justify-between mb-2">
          <h2 class="title m-0 flex-auto "> {{ x.companyName }}  </h2>
        </div>
        <div class="flex items-center justify-between">
          <div class="flex items-center gap-2">
            <span class="font-bold"> {{ ('Bank Code:' | translate ) }}</span>
            <span> {{ x.bankCode }}</span>
          </div>
          <div class="flex items-center gap-2">
            <span  class="font-bold"> {{ ('Establishment Type:' | translate )}}</span>
            <span> {{ x.companyType }}</span>
          </div>
        </div>
        <div class="flex items-center justify-between">
          <div class="flex items-center gap-2">
            <span class="font-bold"> {{ ('License No:' | translate ) }}</span>
            <span> {{ x.engineerLicenseNo }}</span>
          </div>
          <div class="flex items-center gap-2">
            <span  class="font-bold"> {{ ('License Type:' | translate )}}</span>
            <span> {{ x.disciplineName }}</span>
          </div>
        </div>

        <div class="flex items-center justify-between">
          <div class="flex items-center gap-2">
            <span class="font-bold"> {{ ('License Start Date:' | translate ) }}</span>
            <span> {{ x.licenseStartDate | date  }}</span>
          </div>
          <div class="flex items-center gap-2">
            <span  class="font-bold"> {{ ('License End Date:' | translate )}}</span>
            <span> {{ x.licenseExpiryDate | date  }}</span>
          </div>
        </div>

        
        
      </div>

    </mat-dialog-content>
    <mat-dialog-actions align="end">
    <button
        color="warn"
        mat-button
        type="button"
        mat-dialog-close 
      >
        {{'Close' | translate}}        
      </button>
      
    </mat-dialog-actions>

  `,
  styles: [``],
})

export class LicenseModal implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public licenseData: any,
    private dialogRef: MatDialogRef<LicenseModal>,
  ) {}

  ngOnInit(): void {}

 
}
