<div class="vex-user-menu">
  <a
    (click)="viewProfile()"
    class="vex-user-menu-item !gap-2"
    matRipple
    matRippleColor="rgb(var(--vex-color-primary-600), 0.1)">
    <mat-icon
      class="vex-user-menu-item__icon icon-sm"
      svgIcon="mat:account_circle"></mat-icon>
    <div class="vex-user-menu-item__label">{{ 'My Profile' | translate }}</div>
  </a>
  <a
    (click)="changePassword()"
    class="vex-user-menu-item !gap-2"
    matRipple
    matRippleColor="rgb(var(--vex-color-primary-600), 0.1)">
    <mat-icon
      class="vex-user-menu-item__icon icon-sm"
      svgIcon="mat:key"></mat-icon>
    <div class="vex-user-menu-item__label">{{ 'Change Password' | translate }}</div>
  </a>
  <a
    class="vex-user-menu-item !gap-2"
    (click)="openMoreInfoModal()"
    matRipple
    matRippleColor="rgb(var(--vex-color-primary-600), 0.1)"
    *ngIf="appLevel && layoutSrv.isMobile()"
  >
    <mat-icon cclass="vex-user-menu-item__icon icon-sm"  [svgIcon]="appLevel.icon"></mat-icon>
    <div class="vex-user-menu-item__label">{{appLevel.label | translate }}</div>
    <div class="vex-user-menu-item__badge">{{ 'New' | translate }}</div>
  </a>
  <a
    (click)="logOut()"    
    class="vex-user-menu-item !gap-2"
    matRipple
    matRippleColor="rgb(var(--vex-color-primary-600), 0.1)"
  >
    <mat-icon
      class="vex-user-menu-item__icon icon-sm"
      svgIcon="mat:logout"
    ></mat-icon>
    <div class="vex-user-menu-item__label">{{ 'Sign Out' | translate }}</div>
  </a>
</div>
  <!-- <a
    (click)="viewOpenApplication()"
    class="vex-user-menu-item gap-2"
    matRipple
    matRippleColor="rgb(var(--vex-color-primary-600), 0.1)">
    <mat-icon
      class="vex-user-menu-item__icon icon-sm"
      svgIcon="mat:insights"></mat-icon>
    <div class="vex-user-menu-item__label">{{ 'Open Application' | translate }}</div>
    <div class="vex-user-menu-item__badge">{{'Require Attention' | translate }}</div>
  </a> -->

  
  <!-- <a
    (click)="close()"
    [routerLink]="['/apps/social']"
    class="vex-user-menu-item gap-2"
    matRipple
    matRippleColor="rgb(var(--vex-color-primary-600), 0.1)">
    <mat-icon
      class="vex-user-menu-item__icon icon-sm"
      svgIcon="mat:account_circle"></mat-icon>
    <div class="vex-user-menu-item__label">Your Profile</div>
  </a>

  <a
    (click)="close()"
    [routerLink]="['/']"
    class="vex-user-menu-item gap-2"
    matRipple
    matRippleColor="rgb(var(--vex-color-primary-600), 0.1)">
    <mat-icon
      class="vex-user-menu-item__icon icon-sm"
      svgIcon="mat:insights"></mat-icon>
    <div class="vex-user-menu-item__label">Analytics</div>
    <div class="vex-user-menu-item__badge">NEW</div>
  </a>

  <a
    (click)="close()"
    [routerLink]="['/apps/social/timeline']"
    class="vex-user-menu-item gap-2"
    matRipple
    matRippleColor="rgb(var(--vex-color-primary-600), 0.1)">
    <mat-icon
      class="vex-user-menu-item__icon icon-sm"
      svgIcon="mat:settings"></mat-icon>
    <div class="vex-user-menu-item__label">Account Settings</div>
  </a>

  <div class="border-b border-divider mx-4"></div>

  <div
    class="vex-user-menu-item gap-2"
    matRipple
    matRippleColor="rgb(var(--vex-color-primary-600), 0.1)">
    <mat-icon
      class="vex-user-menu-item__icon icon-sm"
      svgIcon="mat:switch_account"></mat-icon>
    <div class="vex-user-menu-item__label">Switch Account</div>
    <mat-icon
      class="vex-user-menu-item__icon icon-sm"
      svgIcon="mat:chevron_right"></mat-icon>
  </div> -->
