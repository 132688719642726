<form [formGroup]="addressForm" *ngIf="isLoaded">
  <div 
    class="flex items-center justify-between  !px-3 sm:!px-6" 
    mat-dialog-title
  >
  <h4 class="m-0 flex-auto"> 
    {{ "Address Form" | translate }} 
  </h4>

    <button
      class="text-secondary"
      mat-dialog-close
      mat-icon-button
      type="button"
    >
      <mat-icon svgIcon="mat:close"></mat-icon>
    </button>
  </div>

  <mat-divider class="text-border"></mat-divider>

  <mat-dialog-content class="flex flex-col !pb-0 !max-h-[70vh] !px-3 sm:!px-6">
    <div class="grid grid-cols-1 md:grid-cols-2 md:gap-x-6 mt-6">
      <mat-form-field [ngClass]="{'md:col-span-2': !isCustomTitle}">
        <mat-label>
          {{ "Address Format" | translate }}
        </mat-label>
  
        <mat-select 
          formControlName="addressFormatID" 
          required
          (selectionChange)="selectCountry($event)"
        >
          <mat-option
            *ngFor="let option of (containerSrv.FormSetup_O | async).addressFormat"
            [value]="option.id"
          >
            {{ option.name }}
          </mat-option>
        </mat-select>  
      </mat-form-field>

      <mat-form-field [ngClass]="{'hidden':  !isCustomTitle}">
        <mat-label>
          {{ "Title" | translate }}
        </mat-label>
        <input
          matInput
          formControlName="title" 
        />
      </mat-form-field>    

      <mat-form-field>
        <mat-label>
          {{ "Flat" | translate }}
        </mat-label>
        <input 
          formControlName="address1" 
          matInput 
        />
      </mat-form-field>

      <mat-form-field>
        <mat-label>
          {{ "Building No" | translate }}
        </mat-label>
        <input 
          formControlName="address2" 
          matInput 
        />
      </mat-form-field>

      <mat-form-field>
        <mat-label>
          {{ "Road No" | translate }}
        </mat-label>
        <input 
          formControlName="address3" 
          matInput 
        />
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ "Block No" | translate }}</mat-label>
        <input 
          formControlName="address4" 
          matInput 
        />
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ "Area" | translate }}</mat-label>
        <input 
          formControlName="address5" 
          matInput 
        />
      </mat-form-field>

      <mat-form-field>
        <mat-label>
          {{ "P.O. Box" | translate }}
        </mat-label>
        <input 
          formControlName="poBox" 
          matInput 
        />
      </mat-form-field>


      <mat-form-field  >
        <mat-label>
          {{ "Country" | translate }}
        </mat-label>              
        <input 
          [matAutocomplete]="auto" 
          formControlName="countryID"
          required
          matInput 
          
        />
        <mat-autocomplete 
          (optionSelected)="selectCity($event)"
          [displayWith]="displayFn"                
          #auto="matAutocomplete"
          >
          <mat-option
            *ngFor="let option of searchableDropdown_O | async"                  
            [value]="option"

          >
            {{ option.name }}
          </mat-option>
        </mat-autocomplete>
        <button
          (click)="clearFormInput()"
          *ngIf="addressForm.get('countryID')?.value && addressForm.get('countryID')?.value != ''"
          mat-icon-button
          matIconSuffix
          type="button"
        >
          <mat-icon svgIcon="mat:close"></mat-icon>
        </button>

        <button 
          *ngIf="!addressForm.get('countryID')?.value" 
          mat-icon-button 
          matIconSuffix 
          type="button"
        >
          <mat-icon svgIcon="mat:arrow_drop_down"></mat-icon>
        </button>
      </mat-form-field>

      <!-- <mat-form-field>
        <mat-label>
          {{ "Country" | translate }}
        </mat-label>
        <mat-select 
          formControlName="countryID" 
          required
          (selectionChange)="selectCity($event)"
        >
          <mat-option
            *ngFor="let option of (containerSrv.FormSetup_O | async).country"
            [value]="option.id"
          >
            {{ option.name }}
          </mat-option>
        </mat-select>
      </mat-form-field> -->

      <mat-form-field>
        <mat-label>
          {{ "City" | translate }}
        </mat-label>
        <mat-select 
          formControlName="cityID" 
          required
        >
          <mat-option
            *ngFor="let option of (getSelectedCountryCity() | async)"
            [value]="option.id"
          >
            {{ option.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>


    </div>

    <div class="grid grid-cols-1"  class="hidden">
      <mat-form-field>
        <mat-label>
          {{ "Landmark" | translate }}
        </mat-label>
        <input 
          formControlName="address7" 
          matInput 
        />
      </mat-form-field>
    </div>


    <mat-form-field class="hidden">
      <mat-label>
        {{'Coordinates' | translate}}
      </mat-label>
      <input 
        formControlName="latitude" 
        matInput 
      />
    </mat-form-field>
  </mat-dialog-content>
  <mat-divider class="text-border"></mat-divider>

  <mat-dialog-actions align="end" >
    <button 
      mat-button 
      mat-dialog-close 
      type="button"
    >
      {{'Cancel' | translate}}
    </button>

    <button
      color="primary"
      mat-flat-button
      (click)="saveAddress()"
      [disabled]="addressForm.invalid"
    >
      {{ 'Save Address' | translate }}
    </button>
  </mat-dialog-actions>
</form>

<!--Following Time2Code Standards-->
