import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from "@angular/core";
import { VexPopoverService } from "@vex/components/vex-popover/vex-popover.service";
import { ToolbarUserDropdownComponent } from "./toolbar-user-dropdown/toolbar-user-dropdown.component";
import { MatIconModule } from "@angular/material/icon";
import { MatRippleModule } from "@angular/material/core";
import { TokenStorageService } from "src/app/_services/token-storage.service";
import { RouterLink } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { CommonModule } from "@angular/common";
import { Observable, of } from "rxjs";
import { SidenavUserMenuComponent } from "../../sidenav/sidenav-user-menu/sidenav-user-menu.component";
import { environment } from "src/environments/environment";
import { ContactService } from "src/app/_services/contact.service";

@Component({
  selector: "vex-toolbar-user",
  templateUrl: "./toolbar-user.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatRippleModule, MatIconModule, RouterLink, TranslateModule, CommonModule],
})
export class ToolbarUserComponent implements OnInit {
  enviroment = environment;
  public dropdownOpen: boolean = false;
  public isAuthenticated_O: Observable<boolean> = this.contacSrv.isAuthenticated_O;

  public userMenuOpen$: Observable<boolean> = of(false);
  public loggedInUser: Observable<any> = this.tokenStorageSrv.loggedUser_O;

  constructor(
    private popover: VexPopoverService,
    private cd: ChangeDetectorRef,
    public tokenStorageSrv: TokenStorageService,
    public contacSrv: ContactService,
    private readonly popoverService: VexPopoverService,
  ) {

  }

  ngOnInit() {}

  // showPopover(originRef: HTMLElement) {
  //   this.dropdownOpen = true;
  //   this.cd.markForCheck();

  //   const popoverRef = this.popover.open({
  //     content: ToolbarUserDropdownComponent,
  //     origin: originRef,
  //     offsetY: 12,
  //     position: [
  //       {
  //         originX: "center",
  //         originY: "top",
  //         overlayX: "center",
  //         overlayY: "bottom",
  //       },
  //       {
  //         originX: "end",
  //         originY: "bottom",
  //         overlayX: "end",
  //         overlayY: "top",
  //       },
  //     ],
  //   });

  //   popoverRef.afterClosed$.subscribe(() => {
  //     this.dropdownOpen = false;
  //     this.cd.markForCheck();
  //   });
  // }

  openProfileMenu(origin: HTMLDivElement): void {
    this.dropdownOpen = true;
    this.cd.markForCheck();
    const popoverRef = this.popoverService.open({
      content: SidenavUserMenuComponent,
      origin,
      offsetY: 12,
      width: origin.clientWidth,
      position: [
        {
          originX: "center",
          originY: "top",
          overlayX: "center",
          overlayY: "bottom",
        },
        {
          originX: "end",
          originY: "bottom",
          overlayX: "end",
          overlayY: "top",
        },
      ]
    });

    popoverRef.afterClosed$.subscribe(() => {
      this.dropdownOpen = false;
      this.cd.markForCheck();
    });

    // this.userMenuOpen$ = of(
    //   this.popoverService.open({
    //     content: SidenavUserMenuComponent,
    //     origin,
    //     offsetY: 12,
    //     width: origin.clientWidth,
    //     position: [
    //       {
    //         originX: "center",
    //         originY: "top",
    //         overlayX: "center",
    //         overlayY: "bottom",
    //       },
    //       {
    //         originX: "end",
    //         originY: "bottom",
    //         overlayX: "end",
    //         overlayY: "top",
    //       },
    //     ]
    //   })
    // ).pipe(
    //   switchMap((popoverRef) => popoverRef.afterClosed$.pipe(map(() => false))),
    //   startWith(true)
    // );
  }
}
