import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  UntypedFormBuilder,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { Router, RouterLink } from "@angular/router";
import { fadeInUp400ms } from "@vex/animations/fade-in-up.animation";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatIconModule } from "@angular/material/icon";
import { CommonModule } from "@angular/common";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatButtonModule } from "@angular/material/button";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ContainerService } from "src/app/_services/container.service";
import { MatSelectModule } from "@angular/material/select";
import { Subscription } from "rxjs";
import { MatSnackBar, MatSnackBarModule } from "@angular/material/snack-bar";
import { ReCaptchaV3Service, RecaptchaV3Module } from 'ng-recaptcha';
import { OtpModal } from "src/app/_modals/otp.modal";
import { MatDialog } from "@angular/material/dialog";
import { OTP } from "@vex/interfaces/dashboard.interface";
import { FormPanel, mapFailureError } from "@vex/enums/enumerations";
import { TokenStorageService } from "src/app/_services/token-storage.service";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { AutoFillDirective } from "src/app/_directives/auto-fill.directive";
import { NgxMatIntlTelInputComponent } from "ngx-mat-intl-tel-input";
import { WebsiteService } from "src/app/_services/website.service";
import { ContactService } from "src/app/_services/contact.service";
import { SNACKBAR_DURATION_LARGE, SNACKBAR_DURATION_NORMAL } from "src/static-data/constants";



export const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  return control.value.password === control.value.passwordConfirm
    ? null
    : { PasswordNoMatch: true };
};

export const passwordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const password = control.value;

  if (!password) {
    return null; // Don't perform validation if the password is empty
  }

  const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*()_+]).{8,}$/;

  return passwordRegex.test(password)
    ? null
    : { invalidPassword: true };
};

@Component({
  selector: "vex-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.scss"],
  animations: [fadeInUp400ms],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatTooltipModule,
    CommonModule,
    MatIconModule,
    MatCheckboxModule,
    RouterLink,
    TranslateModule,
    NgxMatIntlTelInputComponent,
    MatSelectModule,
    MatSnackBarModule,
    RecaptchaV3Module,
    MatProgressSpinnerModule,
    AutoFillDirective
  ],
})

export class RegisterComponent implements OnInit {
  @ViewChild('phoneInput') phoneInput!: NgxMatIntlTelInputComponent;
  private subscriptions: Subscription = new Subscription();

  public isFormSubmitted: boolean = false;

  public registerForm: FormGroup = this.fb.group({
    applicantType: [null, Validators.required],
    cpr: [null, Validators.required],
    name: [null, Validators.required],
    email: [null, [ Validators.required, Validators.email ]],
    nameArb: [null, Validators.required],
    establishmentType: [null, Validators.required],
    contactMobile: [ null , Validators.required],    
    password: [null,  [Validators.required, passwordValidator]],
    passwordConfirm: [null, [Validators.required, passwordValidator]]    
  },
  { validators: [confirmPasswordValidator ] }
  );

  public configData: any = [];
  public inputType: string = "password";
  public visible: boolean = false;

  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private cd: ChangeDetectorRef,
    public containerSrv: ContainerService,
    public translateSrv: TranslateService,
    public websiteSrv: WebsiteService,
    public snackbar: MatSnackBar,
    public recaptchaSrv: ReCaptchaV3Service,
    private dialog: MatDialog,
    private tokenStorageService: TokenStorageService,
    private contacSrv: ContactService,
  ) {}

  public ngOnInit(): void {
      const ContactSub: Subscription = this.websiteSrv.getRegistrationSetup().subscribe({
        next: (value: any) => {
          if(value && value.succeeded) {
            this.configData = value.data;
          } else {
            this.snackbar.open(
              this.translateSrv.instant("Error Happened While Fetching Data"),
              undefined,
              { 
                duration: SNACKBAR_DURATION_NORMAL
              }
            );
          }
        },
        error: (err: any) => {
          this.snackbar.open(
            err,
            undefined,
            { 
              duration: SNACKBAR_DURATION_NORMAL
            }
          );
        }
      });

      this.subscriptions.add(ContactSub);
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    if(this.phoneInput && this.phoneInput.matMenu)
      this.phoneInput.matMenu.panelClass = 'menu-class';
  }




  public toggleVisibility() {
    if (this.visible) {
      this.inputType = "password";
      this.visible = false;
      this.cd.markForCheck();
    } else {
      this.inputType = "text";
      this.visible = true;
      this.cd.markForCheck();
    }
  }

  public ngOnDestroy() {
    this.registerForm.reset();
    this.subscriptions.unsubscribe();
  }





  public submitUser(): void {
    this.markFormGroupTouched(this.registerForm);  

    if(this.registerForm.valid) {
      let payloadData = this.registerForm.value;
      delete payloadData.passwordConfirm;

      this.isFormSubmitted = true;
      const recaptchaSub = this.recaptchaSrv.execute('importantAction').subscribe((token: string) => {
        if(token) {
          
          let otpData = {
            applicantType: payloadData.applicantType,
            cpr: payloadData.cpr,
            name: payloadData.name,
            email: payloadData.email,
            contactMobile: payloadData.contactMobile,
            reCaptcha: token
          };

          const otpSub =  this.websiteSrv.sendOTP(otpData).subscribe({
            next: (value: any) => {
              if(value && value.succeeded) {
                this.registerUser(payloadData, value.data);   

                             
              } else {        
                this.isFormSubmitted = false;        
                this.snackbar.open(mapFailureError(value.errorCode, this.translateSrv), undefined, { duration: SNACKBAR_DURATION_LARGE });
              }
            },
            error: (err: any) => {
              this.isFormSubmitted = false;
              this.snackbar.open(this.translateSrv.instant("Error Occurred while verifying your Initial Request"), undefined, { duration: SNACKBAR_DURATION_LARGE });
            }
          });
          this.subscriptions.add(otpSub);
        }
      });

      this.subscriptions.add(recaptchaSub);
    }    
  }

  private registerUser(payloadData: any, reference: string): void {    
    this.dialog.open(OtpModal, { 
      data: {
        ref: reference,
        contactMobile: payloadData.contactMobile,
        email: payloadData.email
      },
    }).afterClosed().subscribe((otpData: OTP) => {
      this.isFormSubmitted = false;
      if (otpData) {        
        payloadData.reference = otpData.reference;
        payloadData.otp = otpData.otp;
        const registerSub = this.websiteSrv.registerUser(payloadData).subscribe({
          next: (value: any) => {
            if(value && value.succeeded) {
              if(value.data.success) {        
                if(value.data.authenticationResponse) {
                  this.tokenStorageService.saveToken(value.data.authenticationResponse.token);
                  this.tokenStorageService.saveUser(value.data.authenticationResponse);
      
                  this.contacSrv.isAuthenticated_B.next(true);

                  this.router.navigate(["/form/comp-form"], {
                    queryParams: { formType: FormPanel.RegisterCompany }
                  });
                  this.snackbar.open(this.translateSrv.instant("Your account is successfully created. Please continue to register your company"), undefined, { duration: SNACKBAR_DURATION_LARGE });

                } else {
                  this.router.navigate(['login']);
                  this.snackbar.open(this.translateSrv.instant("Your account is successfully created. Please log in to complete the registration process."), undefined, { duration: SNACKBAR_DURATION_LARGE });
                }
                
                

              } else {
                this.isFormSubmitted = false; 
                this.snackbar.open(this.translateSrv.instant("OTP Verification Failed"), undefined, { duration: SNACKBAR_DURATION_NORMAL });
              }
              
            } else {
              this.snackbar.open(this.translateSrv.instant("OTP Verification Failed"), undefined, { duration: SNACKBAR_DURATION_NORMAL });
              //todo: show error 
            }
          },
          error: (err: any) => {
            this.snackbar.open(this.translateSrv.instant("Error Occurred while verifying your OTP request"), undefined, { duration: SNACKBAR_DURATION_LARGE });
          }
        });
        this.subscriptions.add(registerSub);
      }
    });
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach(control => {
      control.markAsTouched();
  
      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }

}
