<div 
  #userProfileRef 
  (click)="openProfileMenu(userProfileRef)"  
  class="m-2 flex items-center gap-2 rounded cursor-pointer relative transition duration-400 ease-out-swift select-none py-1 px-2 hover:bg-hover"
  matRipple 
  *ngIf="(isAuthenticated_O | async)"
>
  <div class="h-9 w-9 rounded-full overflow-hidden ">
    <img alt="User Avatar" class="w-full h-full object-cover" [src]="((loggedInUser | async ).avatarImage != null && (loggedInUser | async ).avatarImage != '') ? ( enviroment.apiUrl + '/File/Processor?cipherText=' + (loggedInUser | async ).avatarImage) : '/assets/img/avatars/avatar.png'" />
  </div>
  <div class="vex-sidenav-user__content flex-auto">
    <div class="vex-sidenav-user__title"> {{ (loggedInUser | async ).employeeName }}</div>
    <div class="vex-sidenav-user__subtitle text-xs">{{ (loggedInUser | async ).title }}</div>
  </div>
  <mat-icon
    class="vex-sidenav-user__dropdown-icon flex-none"
    svgIcon="mat:arrow_drop_down"></mat-icon>
</div>
<a
  [routerLink]="['/login']" 
  class="flex items-center rounded cursor-pointer relative transition duration-400 ease-out-swift select-none py-1 pr-1 pl-3 hover:bg-hover"
  matRipple
  *ngIf="!(isAuthenticated_O | async)"
>
  <div
    class="body-1 font-medium leading-snug hidden sm:block"
  >
    {{'Login' | translate }}
  </div>
  <div
    class="rounded-full h-9 w-9 flex items-center justify-center text-white-600 bg-white-600/10"
  >
    <mat-icon svgIcon="mat:login"></mat-icon>
  </div>
</a>