<div class="w-full h-full bg-pattern flex flex-col items-center justify-center p-4">
  <div 
    @fadeInUp 
    class="card overflow-hidden w-full max-w-3xl  mt-4" 
    *ngIf="configData"
  >
    <div class="text-center mt-4">
      <h2 class="title m-0">
        {{ 'Registering New Entity Form' | translate }}
      </h2>
      <h4 class="body-2 text-secondary m-0">
        {{ 'Simply fill out the form below' | translate }}
      </h4>
    </div>

    <form 
      #f1="ngForm"
      [formGroup]="registerForm" 
      class="p-6"       
    >
    <div class="flex flex-col sm:flex-row sm:gap-x-3">
      <mat-form-field  class="w-full sm:w-[200px]">
        <mat-label>
          {{ "Applicant Type" | translate }}
        </mat-label>
        <mat-select formControlName="applicantType">
          <mat-option
            *ngFor="let option of configData.applicantTypes"
            [value]="option.id"
          >
            {{ option.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field  class="grow">
        <mat-label>
          {{ "Establishment Type" | translate }}
        </mat-label>
        <mat-select formControlName="establishmentType">
          <mat-option
            *ngFor="let option of configData.establishmentTypes"
            [value]="option.id"
          >
            {{ option.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="grow">
        <mat-label>{{ 'Personal ID' | translate }}</mat-label>
        <input 
          formControlName="cpr" 
          matInput 
        />
      </mat-form-field>
    </div>
          
    <div class="flex flex-col sm:flex-row sm:gap-x-3">
      <mat-form-field class="grow">
        <mat-label>{{ 'Full Name (English)' | translate }}</mat-label>
        <input 
          formControlName="name" 
          matInput 
          required 
        />
      </mat-form-field>
      <mat-form-field class="grow">
        <mat-label>{{ 'Full Name (Arabic)' | translate }}</mat-label>
        <input 
          formControlName="nameArb" 
          matInput 
          required 
        />
      </mat-form-field>
    </div>

    <div class="flex flex-col sm:flex-row sm:gap-x-3">
      <mat-form-field class="grow">
        <mat-label>{{ 'Email' | translate }}</mat-label>
        <input 
          formControlName="email" 
          matInput 
          cdkFocusInitial
        />
      </mat-form-field>
      <mat-form-field class="grow">
        <mat-label>{{ 'Mobile No' | translate }}</mat-label>

        <ngx-mat-intl-tel-input
          [cssClass]="'custom'"
          [preferredCountries]="['bh', 'sa']"  
          [inputPlaceholder]="phoneInput.selectedCountry?.placeHolder || ''"
          [enablePlaceholder]="true"
          formControlName="contactMobile" 
          #phoneInput
          [required]="true"
        ></ngx-mat-intl-tel-input> 
      </mat-form-field>
    </div>

    <div class="flex flex-col sm:flex-row sm:gap-x-3">
      <mat-form-field class="grow">
        <mat-label>{{ 'Password' | translate }}</mat-label>
        <input
          [type]="inputType"
          formControlName="password"
          matInput
          required
        />
        <button
          (click)="toggleVisibility()"
          mat-icon-button
          matIconSuffix
          [matTooltip]="'Toggle Visibility' | translate "
          type="button"
        >
          <mat-icon *ngIf="visible" svgIcon="mat:visibility"></mat-icon>
          <mat-icon *ngIf="!visible" svgIcon="mat:visibility_off"></mat-icon>
        </button>       
      </mat-form-field>

      <mat-form-field class="grow">
        <mat-label>{{ 'Confirm Password' | translate }}</mat-label>
        <input
          [type]="inputType"
          formControlName="passwordConfirm"
          matInput
          required
        />
        <button
          (click)="toggleVisibility()"
          mat-icon-button
          matIconSuffix
          [matTooltip]="'Toggle Visibility' | translate "
          type="button"
        >
          <mat-icon *ngIf="visible" svgIcon="mat:visibility"></mat-icon>
          <mat-icon *ngIf="!visible" svgIcon="mat:visibility_off"></mat-icon>
        </button>
        <mat-error *ngIf="registerForm.hasError('PasswordNoMatch') && registerForm.get('passwordConfirm')?.touched">
          {{ 'Password do not match' | translate }}
        </mat-error>
      </mat-form-field>
    </div>

    <mat-label class="text-xs">
      <b>{{ 'Password Must Contain:' | translate }}</b>
      <ul class="mb-4">
        <li>{{ 'At Least One Uppercase and One Lowercase Letter.' | translate }}</li>
        <li>{{ 'At Least One Number.' | translate }}</li>
        <li>{{ 'At Least One Special Character.' | translate }}</li>
        <li>{{ 'Password Length (8 Letters Min).' | translate }}</li>
      </ul>
    </mat-label>


    <button 
    (click)="submitUser()" 
    color="primary" 
    class="flex mx-auto mb-3 transition-none"
    mat-raised-button 
    type="button"
    [disabled]="isFormSubmitted"
  > 
    <span *ngIf="!isFormSubmitted">{{ 'CREATE ACCOUNT' | translate }}</span>
    
    <mat-progress-spinner 
      class="!w-5 !h-5"
      mode="indeterminate" 
      color="accent"
      *ngIf="isFormSubmitted"
    ></mat-progress-spinner> 
  </button>
  <p class="text-center">
    <span class="text-secondary">{{ 'Already have an account?' | translate }}</span>
    <br/>
    <a [routerLink]="['/login']" class="transition-all text-primary-600 hover:text-primary-800">{{ 'Sign in here' | translate }}</a>
  </p>
    


    </form>
  </div>
</div>
