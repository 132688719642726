<div
  (mouseenter)="collapseOpenSidenav()"
  (mouseleave)="collapseCloseSidenav()"
  [class.collapsed]="collapsed"
  [class.open]="collapsed && (collapsedOpen$ | async)"
  class="vex-sidenav flex flex-col"
>
  <div class="vex-sidenav-toolbar__container">
    <div class="sidenav-toolbar flex-none flex items-center justify-center">
      <img
        alt="Logo"
        class="w-[175px] p-1 bg-white rounded overflow-hidden"
        src="assets/img/logo/mun-logo-1.svg"    
        (click)="navigateToHome()"    
      />
    </div>
    <div class="vex-sidenav-toolbar__divider"></div>
  </div>

  <vex-scrollbar class="grow h-full flex flex-col relative">
    <user-navigation></user-navigation>
    <div class="before:absolute before:inset-0  before:bg-[url('src/assets/img/logo/crpep-logo-min.png')] before:bg-contain before:-z-10 before:bg-center before:bg-no-repeat before:opacity-40"></div>
    <!-- <div class="pt-2 flex-1">
               <div class="w-40 h-full">
        <img [src]="imageUrl$ | async" alt="Logo" class="w-full h-full object-contain" />
      </div>    
    </div> -->


  </vex-scrollbar>
  <div class="vex-sidenav-user__container flex-none">
    <!--*ngIf="userVisible$ | async"-->
    <div
      #userProfileMenuOriginRef
      (click)="openProfileMenu(userProfileMenuOriginRef)"
      [class.vex-sidenav-user--open]="userMenuOpen$ | async"
      class="vex-sidenav-user flex items-center"
      matRipple
      matRippleColor="var(--vex-sidenav-item-ripple-color)"
    >
    <!-- <div class="h-9 w-9 rounded-full overflow-hidden ">
      <img alt="User Avatar" class="w-full h-full object-cover" [src]="(tokenStorageService.getUser().avatarImage != null && tokenStorageService.getUser().avatarImage != '') ? ( environment.apiUrl + '/File/Processor?cipherText=' + tokenStorageService.getUser().avatarImage) : '/assets/img/avatars/avatar.png'" />
    </div> -->
      <img alt="User Avatar" class="vex-sidenav-user__image flex-none" [src]="(tokenStorageSrv.getUser().avatarImage != null && tokenStorageSrv.getUser().avatarImage != '') ? ( environment.apiUrl + '/File/Processor?cipherText=' + tokenStorageSrv.getUser().avatarImage) : '/assets/img/avatars/avatar.png'" />
      <div class="vex-sidenav-user__content flex-auto">
        <div class="vex-sidenav-user__title !whitespace-normal">
          {{ tokenStorageSrv.getUser().employeeName }}
        </div>
        <div class="vex-sidenav-user__subtitle">{{ tokenStorageSrv.getUser().title }}</div>
      </div>
      <mat-icon
        class="vex-sidenav-user__dropdown-icon flex-none"
        svgIcon="mat:arrow_drop_down"
      ></mat-icon>
    </div>
  </div>
</div>

<!-- <img [src]="imageUrl$ | async" alt="Logo" class="w-6 select-none flex-none" />
      <h2 class="vex-sidenav-toolbar__headline flex-auto"> {{ title$ | async }} </h2> -->
<!-- <button (click)="toggleCollapse()" *ngIf="showCollapsePin$ | async" class="vex-sidenav-toolbar__collapse-toggle -mr-4 leading-none flex-none hidden lg:block" mat-icon-button
        type="button">
        <mat-icon *ngIf="!collapsed" class="icon-xs" svgIcon="mat:radio_button_checked"></mat-icon>
        <mat-icon *ngIf="collapsed" class="icon-xs" svgIcon="mat:radio_button_unchecked"></mat-icon>
      </button> -->

<!-- <div *ngIf="searchVisible$ | async" class="vex-sidenav-search__container hidden">
      <div (click)="openSearch()" class="vex-sidenav-search relative" matRipple matRippleColor="var(--vex-sidenav-item-ripple-color)">
        <mat-icon class="flex-none vex-sidenav-search__icon" svgIcon="mat:search"></mat-icon>
        <div class="flex-auto vex-sidenav-search__placeholder"> Quick Search </div>
        <div class="flex-none vex-sidenav-search__keybind">Ctrl K</div>
      </div>
    </div> -->

<!-- <vex-sidenav-item
        *ngFor="let item of items$ | async; trackBy: trackByRoute"
        [item]="item"
        [level]="0">
      </vex-sidenav-item> -->
