<div class="py-3 md:py-2 px-4 grid items-center grid-cols-1 sm:flex sm:gap-2 sm:justify-between grow">
  <a
    class="inline-grid sm:flex items-center sm:justify-start sm:gap-1 md:gap-2 transition-all hover:text-primary-600 justify-center "
    target="_blank"
    href="https://www.mun.gov.bh/"
  >
    <div class="flex items-center sm:gap-1 md:gap-2">
      <div>
        <mat-icon class="icon-xs" svgIcon="mat:copyright"></mat-icon>   
      </div>
      <span class="text-xs">{{ 'Ministry of Municipalities Affairs and Agriculture - Kingdom of Bahrain' | translate }}</span>
    </div>    
    <span class="text-xs text-end sm:text-start">{{ 'All Rights Reserved (2024)' | translate }}</span>
  </a>
  <a
    class="flex items-center  sm:gap-1 md:gap-2 group mt-3 sm:mt-0 justify-center sm:justify-end"
    target="_blank"
    href="https://www.amthalgroup.com/"
  >
    <span class="text-xs">{{ 'Developed With' | translate }}</span>
    <div>
      <mat-icon class="icon-xs text-red-600" svgIcon="mat:favorite"></mat-icon>
    </div>
    <span>{{ 'by' | translate }}</span>   
    <span class="text-xs transition-all text-blue-600 group-hover:text-primary-600">{{ 'Amthal Group' | translate }}</span>
  </a>
</div>
