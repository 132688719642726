import {
  Component,
  DestroyRef,
  HostBinding,
  inject,
  OnInit,
} from "@angular/core";
import { VexLayoutService } from "@vex/services/vex-layout.service";
import { VexConfigService } from "@vex/config/vex-config.service";
import { filter, map, startWith } from "rxjs/operators";
import { VexPopoverService } from "@vex/components/vex-popover/vex-popover.service";
import { Observable, of } from "rxjs";
import { ToolbarUserComponent } from "./toolbar-user/toolbar-user.component";
import { MatMenuModule } from "@angular/material/menu";
import { NavigationEnd, Router, RouterLink } from "@angular/router";
import { CommonModule } from "@angular/common";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { checkRouterChildsData } from "@vex/utils/check-router-childs-data";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { NavigationItem } from "src/app/core/navigation/navigation-item.interface";
import { NavigationService } from "src/app/core/navigation/navigation.service";
import { TranslationService } from "src/app/_services/translation.service";
import { NavigationComponent } from "src/app/layouts/components/navigation/navigation.component";
import { RoutingTab } from "@vex/enums/enumerations";
import { ContactService } from "src/app/_services/contact.service";

@Component({
  selector: "vex-toolbar",
  templateUrl: "./toolbar.component.html",
  styleUrls: ["./toolbar.component.scss"],
  standalone: true,
  imports: [
    MatButtonModule,
    MatIconModule,
    CommonModule,
    RouterLink,
    MatMenuModule,
    ToolbarUserComponent,
    NavigationComponent  
  ],
})

export class ToolbarComponent implements OnInit {
  @HostBinding("class.shadow-b")

  RoutingTab = RoutingTab;
  public showShadow: boolean = false;

  navigationItems$: Observable<NavigationItem[]> = this.navigationService.items$;
  isLengthMore$: Observable<boolean> = this.navigationItems$.pipe(map((array) => array.length > 1), );
  isVerticalLayout$: Observable<boolean> = this.configService.config$.pipe( map((config) => config.layout === "vertical"), );

  ltMd$: Observable<boolean> = this.layoutService.ltMd$;
  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  public isAuthenticated_O: Observable<boolean> = this.contactSrv.isAuthenticated_O;

  constructor(
    private readonly layoutService: VexLayoutService,
    private readonly configService: VexConfigService,
    private readonly navigationService: NavigationService,
    private readonly router: Router,
    private contactSrv: ContactService,
    public tSrv: TranslationService,
  ) {
  }

  ngOnInit() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        startWith(null),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.showShadow = checkRouterChildsData(
          this.router.routerState.root.snapshot,
          (data) => data.toolbarShadowEnabled ?? false,
        );
      });
  }

  openSidenav(): void {
    this.layoutService.openSidenav();
  }

  useLanguage(language: string) {
    localStorage.setItem("language", language);

    window.location.reload();
  }
}

// megaMenuOpen$: Observable<boolean> = of(false);

// openQuickpanel(): void {
//   this.layoutService.openQuickpanel();
// }

// openMegaMenu(origin: ElementRef | HTMLElement): void {
//   this.megaMenuOpen$ = of(
//     this.popoverService.open({
//       content: MegaMenuComponent,
//       origin,
//       offsetY: 12,
//       position: [
//         {
//           originX: 'start',
//           originY: 'bottom',
//           overlayX: 'start',
//           overlayY: 'top'
//         },
//         {
//           originX: 'end',
//           originY: 'bottom',
//           overlayX: 'end',
//           overlayY: 'top'
//         }
//       ]
//     })
//   ).pipe(
//     switchMap((popoverRef) => popoverRef.afterClosed$.pipe(map(() => false))),
//     startWith(true)
//   );
// }

// openSearch(): void {
//   this.layoutService.openSearch();
// }
// isNavbarInToolbar$: Observable<boolean> = this.configService.config$.pipe(
//   map((config) => config.navbar.position === 'in-toolbar')
// );
// isNavbarBelowToolbar$: Observable<boolean> = this.configService.config$.pipe(
//   map((config) => config.navbar.position === 'below-toolbar')
// );
// userVisible$: Observable<boolean> = this.configService.config$.pipe(
//   map((config) => config.toolbar.user.visible)
// );
// title$: Observable<string> = this.configService.select(
//   (config) => config.sidenav.title
// );
// isHorizontalLayout$: Observable<boolean> = this.configService.config$.pipe(
//   map((config) => config.layout === 'horizontal')
// );

// isDesktop$: Observable<boolean> = this.layoutService.isDesktop$;
